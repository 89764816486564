import React, { FunctionComponent, useRef, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import StyledProps from '../../types/StyledProps'
import { Transition } from 'react-transition-group'

interface FadeProps {
    state: 'entering' | 'entered' | 'exiting' | 'exited' | 'unmounted'
    timeout: number
}

const Fade = styled.div<FadeProps>`
    height: 100%;
    transition: all ease ${({ timeout }) => timeout}ms;
    opacity: 1;

    ${({ state }) =>
        (state === 'entering' || state === 'entered') &&
        css`
            opacity: 1;
        `}

    ${({ state }) =>
        (state === 'exiting' || state === 'exited') &&
        css`
            opacity: 0;
        `}
`

export const FadeTransition: FunctionComponent = ({ children, ...rest }) => {
    const duration = 300
    const scrollRef = useRef<HTMLDivElement>(null)

    /**
     * Petit trick pour que le fadeIn fonctionne avec SwitchTransition
     */
    useEffect(() => {
        if (scrollRef.current) {
            const div = scrollRef.current
            /* div.scrollIntoView({ block: 'end', behavior: 'smooth' }) */
            div.focus()
        }
    }, [])

    return (
        <Transition timeout={duration} {...rest}>
            {(state) => (
                <Fade timeout={duration} state={state} ref={scrollRef}>
                    {children}
                </Fade>
            )}
        </Transition>
    )
}

interface IconProps extends StyledProps {
    loading?: boolean
}

const Icon: FunctionComponent<IconProps> = ({ className }) => (
    <span className={className}>
        <FontAwesomeIcon icon={faCircleNotch} color="#aaa" size="1x" spin />
    </span>
)

const IconStyled = styled(Icon)`
    position: absolute;
    left: 1rem;
    transform: scale(0);
    transition: all ease 300ms;

    ${(props) =>
        props.loading &&
        css`
            transform: scale(1);
        `}
`

interface ButtonProps
    extends React.DetailedHTMLProps<
        React.ButtonHTMLAttributes<HTMLButtonElement>,
        HTMLButtonElement
    > {
    loading?: boolean
    active?: boolean
    className?: string
}

const ButtonWrapper: FunctionComponent<ButtonProps> = ({
    children,
    className,
    active,
    loading,
    ...rest
}) => (
    <button className={className} {...rest}>
        <IconStyled loading={loading} />
        {children}
    </button>
)

const Button = styled(ButtonWrapper)`
    position: relative;
    margin: 0.5rem;
    border: 1px solid lightgray;
    border-radius: 25px;
    background-color: white;
    padding: 0.5rem 1rem;
    outline: none;
    transition: all ease 300ms;

    ${(props) =>
        props.loading &&
        css`
            padding-left: 2.5rem;
        `}

    &:hover:enabled {
        background-color: #eee;
    }
    &:focus {
        outline: none;
    }

    ${(props) =>
        props.active &&
        css`
            background-color: #ddd;
        `}
`

const Block: FunctionComponent<any> = ({ children, ...rest }) => (
    <div {...rest}>
        <div>{children}</div>
    </div>
)

const Title = styled(Block)`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    font-weight: bold;
`

const Text = styled(Block)`
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
`

const Label = styled('span')`
    display: flex;
    flex-direction: row;
    justify-content: start;
    margin-top: 10px;
    font-size: 0.9rem;
`

const Input = styled('input')`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
`

const Textarea = styled('textarea')`
    width: 100%;
    height: 75px;
    display: flex;
    flex-direction: column;
    justify-content: center;
`

const Buttons = styled.div`
    display: flex;
    justify-content: center;
`

interface Props {
    dashed?: boolean
}

const Card = styled.div<Props>`
    display: flex;
    flex-direction: column;
    height: calc(100% - 2 * 1rem);
    border: 1px solid lightgray;
    margin: 1rem;
    padding: 1rem;
    border-radius: 1rem;
    background-color: white;
    overflow: auto;

    ${({ dashed }) =>
        dashed &&
        css`
            border-style: dashed;
            border-color: #999;
        `}
`

const Link = styled.div`
    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }
`

export {
    Title,
    Text,
    Buttons,
    Button,
    Card,
    Link,
    Block,
    Label,
    Input,
    Textarea,
}
