import React, { FunctionComponent, useEffect, useState } from 'react'
import fadeOutIn from '../assets/scss/fadeOutIn.module.scss'
import { Question as IQuestion } from '../types/api/question'
import { Solution } from '../types/api/solution'
import Solutions from './Solutions'
import Question from './Question'
import {
    getNextQuestion,
    getRemainingSolutions,
    postSaveUserResponse,
    postSaveUsefulnessSolution,
} from '../api/api'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { useStore } from '../store'
import last from 'lodash/last'
import { MAX_SOLUTIONS } from '../helpers/constants'
import NoSolutionDesktop from './NoSolutionDesktop'

const Questionnaire: FunctionComponent = props => {
    const [previousQuestions, setPreviousQuestions] = useState<IQuestion[]>([])
    const [previousSolutions, setPreviousSolutions] = useState<Solution[][]>([])
    const [question, setQuestion] = useState<IQuestion | null>(null)
    const [solutions, setSolutions] = useState<Solution[]>([])
    const [showingSolutions, setShowingSolutions] = useState<boolean>(false)
    const [initialized, setInitialized] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const { clientID, sessionID } = useStore(state => ({
        clientID: state.faq.clientID,
        sessionID: state.faq.sessionID,
    }))

    useEffect(() => {
        init()
    })

    const init = async () => {
        if (!sessionID) {
            return
        }

        if (!initialized) {
            setInitialized(true)

            setLoading(true)
            await loadNextQuestion()
            setLoading(false)
        }
    }

    const loadNextQuestion = async () => {
        console.log('loadNextQuestion')

        if (question) {
            setPreviousQuestions([...previousQuestions, question])
        }
        if (solutions.length) {
            setPreviousSolutions([...previousSolutions, solutions])
        }

        const questionResp = await getNextQuestion(clientID, sessionID)
        const newQuestion = questionResp.data

        const solutionsResp = await getRemainingSolutions(clientID, sessionID)
        const newSolutions = solutionsResp.data

        if (newSolutions.length <= MAX_SOLUTIONS) {
            setShowingSolutions(true)
        }
        setQuestion(newQuestion)
        setSolutions(newSolutions)
    }

    const setReponse = async (val: boolean) => {
        console.log('setResponse')

        if (!question) {
            return
        }

        const previousQuestion = last(previousQuestions)
        const previousQestionID = previousQuestion ? previousQuestion.id : null

        await postSaveUserResponse(clientID, sessionID, {
            previousQuestion: previousQestionID,
            question: question.id,
            response: val,
        })
        await loadNextQuestion()
    }

    const goBack = () => {
        // On récupère la dernière question posée
        const newPreviousQuestions = [...previousQuestions]
        const previousQuestion = newPreviousQuestions.pop()

        setPreviousQuestions([...newPreviousQuestions])
        if (previousQuestion) {
            setQuestion(previousQuestion)
        }

        // On récupère les dernières solutions lors de la dernière question
        const newPreviousSolutions = [...previousSolutions]
        const _previousSolutions = newPreviousSolutions.pop()

        setPreviousSolutions([...newPreviousSolutions])
        if (_previousSolutions) {
            setSolutions(_previousSolutions)
        }
    }

    const corriger = () => {
        if (showingSolutions) {
            hideSolutions()

            /**
             * Si les solutions sont <= MAX_SOLUTIONS ça veut dire que l'on est
             * entré automatiquement dans la liste des solutions, dans ce cas il
             * faut revenir deux fois en arrière
             */
            if (solutions.length <= MAX_SOLUTIONS) {
                goBack()
            }
            return
        }

        console.log('corriger')
        goBack()
    }

    const reset = () => {
        if (showingSolutions) {
            hideSolutions()
            return
        }

        console.log('reset')

        const firstQuestion = previousQuestions[0]
        setPreviousQuestions([])
        setQuestion(firstQuestion)

        const firstSolutions = previousSolutions[0]
        setPreviousSolutions([])
        setSolutions(firstSolutions)
    }

    const hideSolutions = () => {
        setShowingSolutions(false)
    }

    const showSolutions = () => {
        setShowingSolutions(true)
    }

    const onThumbsUp = (solution: Solution) => {
        console.log(`thumbsUp: ${solution.id}`)
        postSaveUsefulnessSolution(clientID, sessionID, {
            solution: solution.id,
            useful: true,
        })
    }

    const onThumbsDown = (solution: Solution) => {
        console.log(`thumbsDown: ${solution.id}`)
        postSaveUsefulnessSolution(clientID, sessionID, {
            solution: solution.id,
            useful: false,
        })
    }

    const questionRendered =
        question && !showingSolutions ? (
            <CSSTransition
                key={question.id}
                classNames={fadeOutIn}
                timeout={parseInt(fadeOutIn.duration)}
            >
                <Question
                    question={question}
                    onReponse={reponse => setReponse(reponse)}
                />
            </CSSTransition>
        ) : (
            <CSSTransition classNames="" timeout={0}>
                <span />
            </CSSTransition>
        )

    const solutionsRendered = showingSolutions ? (
        <CSSTransition
            key="solutions"
            classNames={fadeOutIn}
            timeout={parseInt(fadeOutIn.duration)}
        >
            <Solutions
                solutions={solutions}
                onThumbsUp={onThumbsUp}
                onThumbsDown={onThumbsDown}
            />
        </CSSTransition>
    ) : (
        <CSSTransition classNames="" timeout={0}>
            <span />
        </CSSTransition>
    )

    const nosolutionRendered =
        !question || !solutions.length ? (
            <CSSTransition
                key="nosolution"
                classNames={fadeOutIn}
                timeout={parseInt(fadeOutIn.duration)}
            >
                <NoSolutionDesktop />
            </CSSTransition>
        ) : (
            <CSSTransition classNames="" timeout={0}>
                <span />
            </CSSTransition>
        )

    const content =
        initialized && !loading ? (
            <CSSTransition
                key="questions"
                classNames={fadeOutIn}
                timeout={parseInt(fadeOutIn.duration)}
            >
                <div className="col-12 col-sm-11 col-md-8 col-lg-6 col-xl-5">
                    <TransitionGroup
                        timeout={parseInt(fadeOutIn.duration)}
                        className="d-flex justify-content-center"
                    >
                        {questionRendered}
                        {solutionsRendered}
                        {nosolutionRendered}
                    </TransitionGroup>
                </div>
            </CSSTransition>
        ) : (
            <CSSTransition
                key="loading"
                classNames={fadeOutIn}
                timeout={parseInt(fadeOutIn.duration)}
            >
                <div>
                    <FontAwesomeIcon
                        icon={faCircleNotch}
                        color="#aaa"
                        size="3x"
                        spin
                    />
                </div>
            </CSSTransition>
        )

    const footer = initialized ? (
        <div>
            <p className="text-center">Il reste {solutions.length} solutions</p>
            <p className="row justify-content-center">
                <button
                    className="btn btn-secondary mr-1 my-1"
                    disabled={!previousQuestions.length && !showingSolutions}
                    onClick={_ => corriger()}
                >
                    Précédent
                </button>
                <button
                    className="btn btn-secondary ml-1 mr-1 my-1"
                    disabled={!previousQuestions.length && !showingSolutions}
                    onClick={_ => reset()}
                >
                    Recommencer
                </button>
                <button
                    className="btn btn-secondary ml-1 my-1"
                    disabled={!solutions.length || showingSolutions}
                    onClick={_ => showSolutions()}
                >
                    Afficher les solutions
                </button>
            </p>
        </div>
    ) : null

    return (
        <div className="flex-fill container d-flex flex-column">
            <div className="flex-fill d-flex align-items-center">
                <TransitionGroup
                    timeout={parseInt(fadeOutIn.duration)}
                    className="row col-12 justify-content-center mx-auto px-0"
                >
                    {content}
                </TransitionGroup>
            </div>
            {footer}
        </div>
    )
}

export default Questionnaire
