import React, { FunctionComponent, useEffect } from 'react'
import fadeOutIn from './assets/scss/fadeOutIn.module.scss'
import './assets/scss/app.scss'
import {
    BrowserRouter,
    MemoryRouter,
    Route,
    Switch,
    Redirect,
} from 'react-router-dom'
import Home from './components/Home'
import Questionnaire from './components/Questionnaire'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import SignIn from './components/SignIn'
import { useActions, useStore } from './store'
import SignInBar from './components/SignInBar'
import Button from './components/assistant/Button'
import Content from './components/assistant/Content'
import useApplicationParams from './hooks/useApplicationParams'

/**
 * En developpement ou si on est en "mode assistant" il est intéressant de
 * travailler avec le slug
 */
const DEV = !process.env.NODE_ENV || process.env.NODE_ENV === 'development'

const url = new URL(document.URL)
const ASSISTANT = url.pathname === '/button' || url.pathname === '/content'

const Router: FunctionComponent = props =>
    DEV || ASSISTANT ? (
        <BrowserRouter {...props} />
    ) : (
        <MemoryRouter {...props} />
    )

const App: FunctionComponent = props => {
    const logged = useStore(state => state.auth.logged)

    // initialise l'application en fonction de l'applicationName fournit dans l'url via le hook custom useApplicationParams
    const [, applicationRouteName] = useApplicationParams()
    const initApplicationName = useActions(actions => actions.faq.initApplicationName)
    useEffect(() => {
        initApplicationName(applicationRouteName)
    }, [initApplicationName, applicationRouteName])

    return (
        <Router>
            <Switch>
                {/* Assistant */}
                <Route path="/button" component={Button} />
                <Route path="/content" component={Content} />
                {/* St bernard classique */}
                <Route
                    render={() => (
                        <div className="app d-flex flex-column bg-light">
                            <div className="p-2 d-flex flex-row flex-wrap align-items-start justify-content-between">
                                <a href="https://www.comwatt.com">
                                    <img src="./images/logo-comwatt.png" alt="#" />
                                </a>
                                <SignInBar />
                            </div>
                            <div className="flex-fill d-flex">
                                <Route
                                    render={({ location }) => (
                                        <TransitionGroup
                                            appear={true}
                                            timeout={parseInt(
                                                fadeOutIn.duration
                                            )}
                                            className="container d-flex flex-column"
                                        >
                                            <CSSTransition
                                                key={location.key}
                                                classNames={fadeOutIn}
                                                timeout={parseInt(
                                                    fadeOutIn.duration
                                                )}
                                            >
                                                <Switch location={location}>
                                                    <Route
                                                        path="/"
                                                        exact
                                                        component={Home}
                                                    />
                                                    <Route
                                                        path="/questionnaire"
                                                        component={
                                                            Questionnaire
                                                        }
                                                    />
                                                    <Route
                                                        path="/signin"
                                                        render={() =>
                                                            logged === false ? (
                                                                <SignIn />
                                                            ) : (
                                                                <Redirect
                                                                    to={{
                                                                        pathname:
                                                                            '/',
                                                                    }}
                                                                />
                                                            )
                                                        }
                                                    />
                                                </Switch>
                                            </CSSTransition>
                                        </TransitionGroup>
                                    )}
                                />
                            </div>
                        </div>
                    )}
                />
            </Switch>
        </Router>
    )
}

export default App
