import React, { FunctionComponent } from 'react'
import { Question as IQuestion } from '../types/api/question'

interface QuestionProps {
    question: IQuestion
    onReponse: (reponse: boolean) => void
}

const Question: FunctionComponent<QuestionProps> = props => {
    return (
        <div className="text-center">
            <h1 className="mb-5">{props.question.title}</h1>
            <p className="mb-5">{props.question.description}</p>
            <p className="row justify-content-center">
                <button
                    className="btn btn-primary mr-1"
                    onClick={_ => props.onReponse(true)}
                >
                    Oui
                </button>
                <button
                    className="btn btn-primary ml-1"
                    onClick={_ => props.onReponse(false)}
                >
                    Non
                </button>
            </p>
        </div>
    )
}

export default Question
