import React, { FunctionComponent } from 'react'
import StyledProps from '../../types/StyledProps'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'

const IconWrapper = styled.span`
    margin-bottom: 0.5rem;
`

const LoadingEl: FunctionComponent<StyledProps> = props => (
    <div className={props.className}>
        <IconWrapper>
            <FontAwesomeIcon icon={faCircleNotch} color="#999" size="2x" spin />
        </IconWrapper>
        <div>Chargement...</div>
    </div>
)

const Loading = styled(LoadingEl)`
    height: calc(100%);
    padding: 2rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-style: italic;
    color: #999;
`

export default Loading
