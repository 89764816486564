import React, { FunctionComponent } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components'
import StyledProps from '../../types/StyledProps'

const LoadingIconEl: FunctionComponent<StyledProps> = props => (
    <div className={props.className}>
        <FontAwesomeIcon icon={faCircleNotch} color="#aaa" size="1x" spin />
    </div>
)

const LoadingIcon = styled(LoadingIconEl)`
    margin-left: 0.5rem;
`

const Row = styled.div`
    display: flex;
    align-items: center;
`

interface HeaderProps extends StyledProps {
    left: string
    right: React.ReactNode
    loading?: boolean
}

const Header: FunctionComponent<HeaderProps> = props => (
    <div className={props.className}>
        <Row>
            {props.left}
            {props.loading && <LoadingIcon />}
        </Row>
        <div>{props.right}</div>
    </div>
)

const HeaderEl = styled(Header)`
    padding: 0.5rem 1rem;
    border-bottom: 1px solid lightgray;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

export default HeaderEl
