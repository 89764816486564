import SharedState from '../types/SharedState'
import MessageObject from '../types/MessageObject'

function sendResize(width: number, height: number) {
    sendDataToParent({
        type: 'RESIZE',
        data: {
            width,
            height,
        },
    })
}

function sendRedirect(url: string) {
    sendDataToParent({
        type: 'REDIRECT',
        data: {
            url,
        },
    })
}

function sendToggleContent() {
    sendDataToParent({
        type: 'TOGGLE_CONTENT',
    })
}

function sendReady() {
    sendDataToParent({
        type: 'READY',
    })
}

function sendState(obj: SharedState) {
    sendDataToParent({
        type: 'STATE',
        data: obj,
    })
}

function sendDataToParent(obj: MessageObject) {
    const objStr = JSON.stringify(obj)
    window.parent.postMessage(objStr, '*')
}

export { sendResize, sendRedirect, sendToggleContent, sendReady, sendState }
