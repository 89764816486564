import { useState, useEffect } from 'react'
import { APPLICATION_PARAMS_NAME, COACH_SLUG, STBERNARD_APPLICATION_NAME, STBERNARD_SLUG } from '../helpers/constants'

function useApplicationParams() {
    const [applicationName, setApplicationName] = useState<string>(STBERNARD_APPLICATION_NAME)
    const [apiRouteName, setApiRouteName] = useState<string>(STBERNARD_SLUG)

    const handleRouteChange = () => {
        const query = new URLSearchParams(window.location.search)
        const applicationName = query.get(APPLICATION_PARAMS_NAME)

        if (applicationName != null) {
            setApplicationName(applicationName)
            setApiRouteName(applicationName === STBERNARD_APPLICATION_NAME ? STBERNARD_SLUG : COACH_SLUG)
        }
    }

    useEffect(() => {
        window.addEventListener('popstate', handleRouteChange)
        handleRouteChange()
        
        return () => {
            window.removeEventListener('popstate', handleRouteChange)
        }
    }, [])

    return [applicationName, apiRouteName]
}

export default useApplicationParams