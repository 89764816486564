import { parsePhoneNumberFromString } from 'libphonenumber-js'

export default function formatPhoneNumber(phone: string) {
    const phoneNumber = parsePhoneNumberFromString(phone)
    if (phoneNumber) {
        const number = phoneNumber.formatInternational()

        if (number.startsWith('+33 ')) {
            return number.replace(/\+33 /i, '(+33)')
        }
        return number
    }
    return phone
}
