import { Action, action, Thunk, thunk, Listen, listen } from 'easy-peasy'
import uuidv4 from 'uuid/v4'
import auth, { AuthModel } from './auth'
import store from '../store'

interface FaqStates {
    clientID: string
    sessionID: string,
    applicationRouteName: string
}

interface FaqActions {
    initTokens: Action<FaqStates>
    initApplicationName: Action<FaqStates, string>
    listeners: Listen<AuthModel>
    init: Thunk<FaqActions, null>
    restoreFromLocalStorage: Action<FaqStates>
    saveToLocalStorage: Action<FaqStates>
}

export interface FaqModel extends FaqStates, FaqActions {}

const faq: FaqModel = {
    clientID: '',
    sessionID: '',
    applicationRouteName: '',
    init: thunk(actions => {
        console.log('Faq init')

        actions.restoreFromLocalStorage()
        actions.initTokens()
        actions.saveToLocalStorage()
    }),
    initTokens: action(state => {
        if (!state.clientID) {
            state.clientID = uuidv4()
        }

        const timestamp = new Date().getTime()
        state.sessionID = timestamp.toString()
    }),
    initApplicationName: action((state, payload) => {
        state.applicationRouteName = payload
    }),
    listeners: listen(on => {
        /**
         * On actualise le token sessionID lorsque l'utilisateur se connécte ou
         * se déconnecte
         */
        on(
            auth.setLogged,
            action(() => {
                setTimeout(() => {
                    store.dispatch.faq.initTokens()
                }, 1)
            })
        )
    }),
    restoreFromLocalStorage: action(state => {
        const clientIDStr = localStorage.getItem('clientID')
        if (clientIDStr) {
            const clientID = JSON.parse(clientIDStr)
            if (clientID) {
                state.clientID = clientID
            }
        }
    }),
    saveToLocalStorage: action(state => {
        const clientIDStr = JSON.stringify(state.clientID)
        localStorage.setItem('clientID', clientIDStr)
    }),
}

export default faq
