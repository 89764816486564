import auth, { AuthModel } from './auth'
import faq, { FaqModel } from './faq'
import assistant, { AssistantModel } from './assistant'

export interface StoreModel {
    auth: AuthModel
    faq: FaqModel
    assistant: AssistantModel
}

const model: StoreModel = {
    auth,
    faq,
    assistant,
}

export default model
