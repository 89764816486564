const APPLICATION_PARAMS_NAME = 'applicationName'
const STBERNARD_APPLICATION_NAME = 'stbernard'
const STBERNARD_SLUG = 'faq'
const COACH_SLUG = 'coach'

const CONTACT_INFOS = { 
    mail: 'moncoachenergie@comwatt.com',
    phone: '0606060606'
}
/**
 * On affiche les solutions lorsque le nombre de solutions restantes est
 * inférieur ou égale à MAX_SOLUTIONS
 */
const MAX_SOLUTIONS = 1
export { STBERNARD_APPLICATION_NAME, MAX_SOLUTIONS, STBERNARD_SLUG, COACH_SLUG, CONTACT_INFOS, APPLICATION_PARAMS_NAME }
