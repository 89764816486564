import React, { FunctionComponent } from 'react'
import { Solution as ISolution } from '../types/api/solution'
import Solution from './Solution'
import NoSolutionDesktop from './NoSolutionDesktop'

interface Props {
    solutions: ISolution[]
    onThumbsUp?: (solution: ISolution) => void
    onThumbsDown?: (solution: ISolution) => void
}

const Solutions: FunctionComponent<Props> = props => {
    const onThumbsUp = (solution: ISolution) => {
        if (props.onThumbsUp) {
            props.onThumbsUp(solution)
        }
    }

    const onThumbsDown = (solution: ISolution) => {
        if (props.onThumbsDown) {
            props.onThumbsDown(solution)
        }
    }

    const solutionsRendered = props.solutions.map((solution, i) => (
        <div className="mb-5" key={solution.id}>
            <Solution
                solution={solution}
                onThumbsUp={onThumbsUp}
                onThumbsDown={onThumbsDown}
            />
        </div>
    ))

    return (
        <div className="text-center">
            {props.solutions.length > 1 ? (
                <div>
                    <h1 className="mb-5">Nos solutions</h1>
                    {solutionsRendered}
                </div>
            ) : (
                solutionsRendered
            )}

            {props.solutions.length > 0 && <NoSolutionDesktop />}
        </div>
    )
}

export default Solutions
