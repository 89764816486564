import React, { FunctionComponent, useRef, useState, useEffect } from 'react'
import QuestionWithStates from '../../types/QuestionWithStates'
import { Title, Text, Buttons, Button, Card } from './styles'

interface QuestionProps {
    question: QuestionWithStates
    onResponse: (yesOrNo: boolean) => void
}

const QuestionEl: FunctionComponent<QuestionProps> = props => {
    const [tmpVal, setTmpVal] = useState<boolean | null>(null)
    const scrollRef = useRef(null)

    const onResponse = (val: boolean) => {
        if (scrollRef.current) {
            const div = (scrollRef.current as unknown) as HTMLDivElement
            setTimeout(() => {
                div.scrollIntoView({ block: 'center', behavior: 'smooth' })
            }, 1)
            setTmpVal(val)
        }
    } 

    const onResponseProps = props.onResponse
    useEffect(() => {
        if (tmpVal === null) {
            return
        }

        const timeout = setTimeout(async () => {
            await onResponseProps(tmpVal)
            setTmpVal(null)
        }, 300)

        return () => clearTimeout(timeout)
    }, [tmpVal, onResponseProps])

    return (
        <Card ref={scrollRef}>
            <Title>{props.question.title}</Title>
            <Text>{props.question.description}</Text>
            <Buttons>
                <Button
                    loading={
                        (props.question.loading &&
                            props.question.yesOrNo === true) ||
                        tmpVal === true
                    }
                    active={
                        !props.question.loading &&
                        props.question.yesOrNo === true &&
                        tmpVal === null
                    }
                    disabled={
                        props.question.loading ||
                        props.question.yesOrNo === true ||
                        tmpVal !== null
                    }
                    onClick={() => onResponse(true)}
                >
                    Oui
                </Button>
                <Button
                    loading={
                        (props.question.loading &&
                            props.question.yesOrNo === false) ||
                        tmpVal === false
                    }
                    active={
                        !props.question.loading &&
                        props.question.yesOrNo === false &&
                        tmpVal === null
                    }
                    disabled={
                        props.question.loading ||
                        props.question.yesOrNo === false ||
                        tmpVal !== null
                    }
                    onClick={() => onResponse(false)}
                >
                    Non
                </Button>
            </Buttons>
        </Card>
    )
}

export default QuestionEl
