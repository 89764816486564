import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { StoreProvider } from 'easy-peasy'
import store from './store'

// Init du store uniquement si la vue est différente du bouton
const url = new URL(document.URL)
if (url.pathname !== '/button') {
    store.dispatch.faq.init(null)
    store.dispatch.auth.init(null)
    store.dispatch.assistant.init()
}

ReactDOM.render(
    <StoreProvider store={store}>
        <App />
    </StoreProvider>,
    document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
