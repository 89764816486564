import React, { FunctionComponent, useState, FormEvent } from 'react'
import StyledProps from '../../types/StyledProps'
import styled from 'styled-components'
import {
    Block,
    Button,
    Text,
    Card,
    Title,
    Input,
    Label,
    Textarea,
} from './styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { CONTACT_INFOS, STBERNARD_APPLICATION_NAME } from '../../helpers/constants'
import formatPhoneNumber from '../../utils/formatPhoneNumber'
import { postOsTicket } from '../../api/api'
import { useToasts } from 'react-toast-notifications'
import useApplicationParams from '../../hooks/useApplicationParams'


const Contact = styled(Block)`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const Link = styled.a`
    color: #609431;
    text-decoration: none;

    :hover {
        text-decoration: underline;
    }
`

const Content = styled(Text)`
    font-style: italic;
    padding: 15px;
`

const Icon = styled(FontAwesomeIcon)`
    color: #777;
    margin-right: 0.5rem;
`

const PhoneLink = styled(Link)`
    font-size: 1.5rem;
`

const NoSolution: FunctionComponent<StyledProps> = (props) => {
    const [applicationName,] = useApplicationParams()
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        subject: '',
        message: '',
        IdIBUser: ''
    })

    const [isFormOsticketSent, setIsFormOsticketSent] = useState(false);
    const [isFormOsticketLoading, setIsFormOsticketLoading] = useState(false);


    const { addToast } = useToasts()


const sendOsTicket = async (e: FormEvent) => {
    e.preventDefault()
    setIsFormOsticketLoading(true);
    const response = await postOsTicket(formData);

    switch (response) {
        case "error":
            setIsFormOsticketLoading(false);
            addToast("Un problème est survenu. Merci de réessayer plus tard.", { appearance: 'error' });
            break;
        case "success":
            setIsFormOsticketSent(true);
            break;
        default:
    }
}

    return (
        <Card dashed className={props.className}>

            {applicationName === STBERNARD_APPLICATION_NAME ? (
                <Content>
                    {!isFormOsticketSent && 
                    <div>
                        <Title>Aucune solution ne correspond à vos attentes</Title>
                        <Content>Merci de remplir ce formulaire</Content>
                        <form onSubmit={sendOsTicket}>
                            <Label>Nom et Prénom</Label>
                            <Input
                                type="text"
                                value={formData.name}
                                required
                                onChange={(e) => {
                                    setFormData({
                                        ...formData,
                                        name: e.target.value,
                                    })
                                }}
                            />
                            <Label>Email</Label>
                            <Input
                                type="email"
                                value={formData.email}
                                required
                                onChange={(e) => {
                                    setFormData({
                                        ...formData,
                                        email: e.target.value,
                                    })
                                }}
                            />
                            <Label>Téléphone</Label>
                            <Input
                                type="tel"
                                value={formData.phone}
                                required
                                onChange={(e) => {
                                    setFormData({
                                        ...formData,
                                        phone: e.target.value,
                                    })
                                }}
                            />
                            <Label>Sujet</Label>
                            <Input
                                type="text"
                                value={formData.subject}
                                required
                                onChange={(e) => {
                                    setFormData({
                                        ...formData,
                                        subject: e.target.value,
                                    })
                                }}
                            />

                            <Label>N°Box (Facultatif)</Label>
                            <Input
                                type="text"
                                value={formData.IdIBUser}
                                onChange={(e) => {
                                    setFormData({
                                        ...formData,
                                        IdIBUser: e.target.value,
                                    })
                                }}
                            />  

                            <Label>Description du problème</Label>
                            <Textarea
                                value={formData.message}
                                required
                                onChange={(e) => {
                                    setFormData({
                                        ...formData,
                                        message: e.target.value,
                                    })
                                }}
                            />
                            <Button disabled={isFormOsticketLoading} style={{ marginTop: '20px' }} type="submit">
                                Envoyer
                            </Button>
                        </form>
                    </div>
                }
                {isFormOsticketSent && 
                        <Content>
                            Votre demande de support a bien été enregistrée, nous vous recontacterons au plus vite.
                        </Content>
                }
                </Content>
            ) : (
                <Contact>
                    <Content>
                        Nous vous invitons à prendre contact avec notre support
                        technique.
                    </Content>
                    {CONTACT_INFOS.phone && (
                        <div>
                            <Icon icon={faPhone} size="1x" flip="horizontal" />
                            <PhoneLink href={`tel:${CONTACT_INFOS.phone}`}>
                                {formatPhoneNumber(CONTACT_INFOS.phone)}
                            </PhoneLink>
                        </div>
                    )}
                    <div>
                        {CONTACT_INFOS.mail.length < 27 && (
                            <Icon icon={faEnvelope} size="1x" />
                        )}
                        <Link
                            href={`mailto:${CONTACT_INFOS.mail}`}
                            target="_blank"
                        >
                            {CONTACT_INFOS.mail}
                        </Link>
                    </div>
                </Contact>
            )}
        </Card>
    )
}

export default NoSolution
