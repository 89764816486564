import { Action, action, Thunk, thunk } from 'easy-peasy'
import { login } from '../api/api'
import { Role } from '../types/api/auth'

interface User {
    email: string
    roles: Role[]
}

interface LoginPayload {
    email: string
    password: string
}

interface AuthStates {
    token: string | null
    user: User | null
    sending: boolean
    logged: boolean
}

interface AuthActions {
    setToken: Action<AuthStates, string | null>
    setUser: Action<AuthStates, User | null>
    setSending: Action<AuthStates, boolean>
    setLogged: Action<AuthStates, boolean>
    login: Thunk<AuthActions, LoginPayload>
    logout: Thunk<AuthActions, null>
    init: Thunk<AuthActions, null>
    restoreFromLocalStorage: Action<AuthStates>
    saveToLocalStorage: Action<AuthStates>
}

export interface AuthModel extends AuthStates, AuthActions {}

const auth: AuthModel = {
    token: null,
    user: null,
    sending: false,
    logged: false,
    setToken: action((state, payload) => {
        state.token = payload
    }),
    setSending: action((state, payload) => {
        state.sending = payload
    }),
    setLogged: action((state, payload) => {
        state.logged = payload
    }),
    setUser: action((state, payload) => {
        state.user = payload
    }),
    login: thunk(async (actions, payload) => {
        actions.setSending(true)

        const resp = await login({
            username: payload.email,
            password: payload.password,
        })
        const data = resp.data

        actions.setToken(data.token)
        actions.setUser({
            email: data.user.email,
            roles: data.user.roles,
        })

        actions.setLogged(true)
        actions.setSending(false)

        actions.saveToLocalStorage()
    }),
    logout: thunk(actions => {
        actions.setToken(null)
        actions.setUser(null)
        actions.setLogged(false)

        actions.saveToLocalStorage()
    }),
    init: thunk(actions => {
        actions.restoreFromLocalStorage()
    }),
    restoreFromLocalStorage: action(state => {
        const tokenStr = localStorage.getItem('token')
        const userStr = localStorage.getItem('user')

        if (tokenStr && userStr) {
            try {
                const token = JSON.parse(tokenStr)
                const user = JSON.parse(userStr)

                if (token && user) {
                    state.token = token
                    state.user = user
                    state.logged = true
                }
            } catch (e) {}
        }
    }),
    saveToLocalStorage: action(state => {
        const tokenStr = JSON.stringify(state.token)
        localStorage.setItem('token', tokenStr)

        const userStr = JSON.stringify(state.user)
        localStorage.setItem('user', userStr)
    }),
}

export default auth
