import React, { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import { APPLICATION_PARAMS_NAME } from '../helpers/constants'
import useApplicationParams from '../hooks/useApplicationParams'

const Home: FunctionComponent = props => {
    const [applicationName, ] = useApplicationParams()

    return (
        <div className="flex-fill container d-flex align-items-center">
            <div className="row mx-auto px-0 col-12 justify-content-center">
                <div className="px-0 col-12 col-sm-11 col-md-8 col-lg-6 col-xl-6">
                    <div className="text-center">
                        <h1 className="mb-5">
                            Bienvenue sur le <br />
                            {applicationName === 'stbernard'
                                ? 'Saint Bernard COMWATT'
                                : 'Coach Énergétique'}
                        </h1>
                        <div className="mb-5 d-flex justify-content-center">
                            <div style={{ maxWidth: 360 }}>
                                Nous allons vous poser plusieurs questions afin
                                de trouver la solution la plus adaptée à votre
                                attente
                            </div>
                        </div>
                        <Link
                            className="btn btn-primary"
                            to={{
                                pathname: '/questionnaire',
                                search: `?${APPLICATION_PARAMS_NAME}=${applicationName}`
                            }}
                        >
                            Commencer
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home
