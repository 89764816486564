import React, { FunctionComponent, Fragment } from 'react'
import { Link, withRouter, RouteComponentProps } from 'react-router-dom'
import { useStore, useActions } from '../store'

const SignInBar: FunctionComponent<RouteComponentProps> = props => {
    const isSignin = props.location.pathname === '/signin'

    const { logged, email, role } = useStore(state => {
        const role = (() => {
            if (!state.auth.user) {
                return null
            }
            const roles = state.auth.user.roles

            if (roles.includes('ROLE_ADMIN')) {
                return 'Admin'
            }
            if (roles.includes('ROLE_PARTNER')) {
                return 'Partenaire'
            }
            if (roles.includes('ROLE_USER')) {
                return 'Utilisateur'
            }
            return null
        })()

        return {
            logged: state.auth.logged,
            email: state.auth.user ? state.auth.user.email : null,
            role,
        }
    })
    const logout = useActions(state => state.auth.logout)

    const onLogout = () => {
        logout(null)
        props.history.push('/')
    }

    return (
        <Fragment>
            {logged ? (
                <div className="d-flex align-items-center">
                    <Fragment>
                        {email} ({role})
                    </Fragment>
                    <button className="btn btn-link" onClick={onLogout}>
                        Déconnexion
                    </button>
                </div>
            ) : isSignin ? (
                <Link className="btn btn-link" to="/">
                    Accueil
                </Link>
            ) : (
                <Link className="btn btn-link" to="/signin">
                    Connexion
                </Link>
            )}
        </Fragment>
    )
}

export default withRouter(SignInBar)
