import React, { FunctionComponent, useState } from 'react'
import { Solution as ISolution } from '../types/api/solution'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faThumbsUp, faThumbsDown } from '@fortawesome/free-solid-svg-icons'

interface Props {
    solution: ISolution
    onThumbsUp?: (solution: ISolution) => void
    onThumbsDown?: (solution: ISolution) => void
}

const Solution: FunctionComponent<Props> = props => {
    const [voted, setVoted] = useState(false)

    const onThumbsUp = () => {
        setVoted(true)
        if (props.onThumbsUp) {
            props.onThumbsUp(props.solution)
        }
    }

    const onThumbsDown = () => {
        setVoted(true)
        if (props.onThumbsDown) {
            props.onThumbsDown(props.solution)
        }
    }

    return (
        <div>
            <h2 className="mb-3">{props.solution.title}</h2>
            <p>{props.solution.description}</p>
            <div>
                <button
                    disabled={voted}
                    className="btn btn-primary mr-1"
                    onClick={onThumbsUp}
                >
                    <FontAwesomeIcon icon={faThumbsUp} color="#fff" />
                </button>
                <button
                    disabled={voted}
                    className="btn btn-primary mr-1"
                    onClick={onThumbsDown}
                >
                    <FontAwesomeIcon icon={faThumbsDown} color="#fff" />
                </button>
            </div>
            <div className="mt-2">
                <a href={props.solution.uri}>En savoir plus</a>
            </div>
        </div>
    )
}

export default Solution
