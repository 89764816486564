import React, { FunctionComponent, useState, useEffect } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { sendReady } from '../../utils/iframe'
import Flow from './Flow'
import Welcome from './Welcome'
import { useStore, useActions } from '../../store'
import { SwitchTransition } from 'react-transition-group'
import { FadeTransition } from './styles'

const GlobalStyle = createGlobalStyle`
    body {
        background-color: transparent;
    }
`

const Div = styled.div`
    display: flex;
    justify-content: stretch;
    align-items: stretch;
    height: 100vh;
    width: 100vw;
    padding: 1rem;
`

const Container = styled.div`
    flex: 1;
    position: relative;
    background-color: white;
    border-radius: 1rem;
    box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.25);
    overflow: auto;
`

const Content: FunctionComponent = () => {
    const { questions, initialized } = useStore(state => ({
        questions: state.assistant.questions,
        initialized: state.assistant.initialized,
    }))

    const [started, setStarted] = useState(false)
    const [loading, setLoading] = useState(false)
    const newSession = useActions(actions => actions.assistant.newSession)

    useEffect(() => {
        if (questions.length > 1) {
            setStarted(true)
        }
    }, [questions])

    useEffect(() => {
        if (initialized) {
            sendReady()
        }
    }, [initialized])

    const onStart = async () => {
        setLoading(true)
        // On attend au moins 300ms avant de passer à la suite
        await Promise.all([
            await newSession(),
            await new Promise(res => setTimeout(res, 300)),
        ])
        setLoading(false)
        setStarted(true)
    }

    return (
        <React.Fragment>
            <GlobalStyle />
            <Div>
                <Container>
                    <SwitchTransition mode="out-in">
                        <FadeTransition key={started ? 'welcome' : 'flow'}>
                            {!started ? (
                                <Welcome loading={loading} onStart={onStart} />
                            ) : (
                                <Flow />
                            )}
                        </FadeTransition>
                    </SwitchTransition>
                </Container>
            </Div>
        </React.Fragment>
    )
}

export default Content
