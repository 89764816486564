import React, { FunctionComponent, useState, useRef, useEffect } from 'react'
import { Title, Text, Buttons, Button, Card, Link } from './styles'
import SolutionWithStates from '../../types/SolutionWithStates'
import styled from 'styled-components'

interface SolutionProps {
    solution: SolutionWithStates
    onFeedback: (goodOrNot: boolean) => void
    onRedirect: () => void
}

const FeedbackQuestion = styled.div`
    color: #aaa;
    font-size: 0.8rem;
    text-align: center;
`

const SolutionEl: FunctionComponent<SolutionProps> = props => {
    const [tmpVal, setTmpVal] = useState<boolean | null>(null)
    const scrollRef = useRef(null)

    const onFeedback = (val: boolean) => {
        if (scrollRef.current) {
            const div = (scrollRef.current as unknown) as HTMLDivElement
            div.scrollIntoView({ block: 'center', behavior: 'smooth' })
            setTmpVal(val)
        }
    }

    const onFeedbackProps = props.onFeedback
    useEffect(() => {
        if (tmpVal === null) {
            return
        }

        const timeout = setTimeout(async () => {
            await onFeedbackProps(tmpVal)
            setTmpVal(null)
        }, 200)

        return () => clearTimeout(timeout)
    }, [tmpVal, onFeedbackProps])

    return (
        <Card ref={scrollRef} dashed>
            <Title>
                <Link onClick={() => props.onRedirect()}>
                    {props.solution.title}
                </Link>
            </Title>
            <Text>
                <Link onClick={() => props.onRedirect()}>
                    {props.solution.description}
                </Link>
            </Text>
            <FeedbackQuestion>
                Cette solution vous a t'elle aidé ?
            </FeedbackQuestion>
            <Buttons>
                <Button
                    loading={
                        (props.solution.loading &&
                            props.solution.goodOrNot === true) ||
                        tmpVal === true
                    }
                    active={
                        !props.solution.loading &&
                        props.solution.goodOrNot === true &&
                        tmpVal === null
                    }
                    disabled={
                        props.solution.goodOrNot !== undefined ||
                        tmpVal !== null
                    }
                    onClick={() => onFeedback(true)}
                >
                    Oui
                </Button>
                <Button
                    loading={
                        (props.solution.loading &&
                            props.solution.goodOrNot === false) ||
                        tmpVal === false
                    }
                    active={
                        !props.solution.loading &&
                        props.solution.goodOrNot === false &&
                        tmpVal === null
                    }
                    disabled={
                        props.solution.goodOrNot !== undefined ||
                        tmpVal !== null
                    }
                    onClick={() => onFeedback(false)}
                >
                    Non
                </Button>
            </Buttons>
        </Card>
    )
}

export default SolutionEl
