import React, { FunctionComponent, useEffect, useState } from 'react'
import styled, { createGlobalStyle, css } from 'styled-components'
import { sendToggleContent, sendReady } from '../../utils/iframe'
import stbernardIdleImage from '../../assets/images/stbernard/idle.png'
import stbernardFoundImage from '../../assets/images/stbernard/found.png'
import stbernardSearchingImage from '../../assets/images/stbernard/searching.png'
import coachIdleImage from '../../assets/images/coach/idle.png'
import SharedState from '../../types/SharedState'
import MessageObject from '../../types/MessageObject'
import useApplicationParams from '../../hooks/useApplicationParams'
import { STBERNARD_APPLICATION_NAME } from '../../helpers/constants'

const GlobalStyle = createGlobalStyle`
    body {
        background-color: transparent;
    }
`

const Div = styled.div`
    display: flex;
    justify-content: stretch;
    align-items: stretch;
    height: 100vh;
    padding: 1rem;
`

const ButtonEl = styled.button`
    flex: 1;
    position: relative;
    background-color: white;
    border-radius: 50%;
    border: none;
    box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.25);
    outline: none;
    transition: all ease 300ms;
    padding: 0.6rem;
    max-height: 100%;

    &:hover {
        background-color: #eee;
    }

    &:active {
        background-color: #ddd;
    }

    &:focus {
        outline: none;
    }
`

interface ImageProps
    extends React.DetailedHTMLProps<
        React.ImgHTMLAttributes<HTMLImageElement>,
        HTMLImageElement
    > {
    className?: string
    animating?: boolean
}

const Image = styled.img<ImageProps>`
    width: 100%;
    height: 100%;
    object-fit: contain;

    ${props =>
        props.animating &&
        css`
            animation: anim 1s infinite steps(2, end);
        `}

    @keyframes anim {
        from {
            transform: rotateY(0deg);
        }
        to {
            transform: rotateY(360deg);
        }
    }
`

const Button: FunctionComponent = () => {
    const [applicationName,] = useApplicationParams()
    const [imgSrc, setImageSrc] = useState(
        applicationName === STBERNARD_APPLICATION_NAME ? stbernardIdleImage : coachIdleImage
    )

    const click = () => {
        sendToggleContent()
    }

    useEffect(() => {
        const handler = (message: MessageEvent) => {
            try {
                const obj: MessageObject = JSON.parse(message.data)

                if (applicationName === STBERNARD_APPLICATION_NAME) {
                    switch (obj.type) {
                        case 'STATE':
                            const state: SharedState = obj.data
                            if (state.loading) {
                                setImageSrc(stbernardSearchingImage)
                            } else if (state.found) {
                                setImageSrc(stbernardFoundImage)
                            } else {
                                setImageSrc(stbernardIdleImage)
                            }
                            break
                    }
                }
            } catch (error) {
                console.warn(error)
            }
        }

        window.addEventListener('message', handler)
        sendReady()

        return () => window.removeEventListener('message', handler)
    }, [applicationName])

    return (
        <React.Fragment>
            <GlobalStyle />
            <Div>
                <ButtonEl onClick={click}>
                    <Image
                        animating={imgSrc === stbernardSearchingImage}
                        src={imgSrc}
                    />
                </ButtonEl>
            </Div>
        </React.Fragment>
    )
}

export default Button
