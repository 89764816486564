import axios from 'axios'
import { AuthResponse, AuthRequest } from '../types/api/auth'
import store from '../store'
import { Question } from '../types/api/question'
import { Solution, UserfulnessSolution } from '../types/api/solution'
import { Response } from '../types/api/response'
import { OsTicketParameters } from '../types/api/osTicketParameters'

const axiosInstance = axios.create()

axiosInstance.interceptors.request.use(
    (config) => {
        const token = store.getState().auth.token
        const routeName = store.getState().faq.applicationRouteName

        config.baseURL = `${process.env.REACT_APP_BACKEND_URL}/api/${routeName}`
        if (token) {
            config.headers.Authorization = `Bearer ${token}`
        }
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

function login(request: AuthRequest) {
    return axiosInstance.post<AuthResponse>(`/auth`, request)
}

function getNextQuestion(userToken: string, sessionToken: string) {
    return axiosInstance.get<Question>(
        `/next_question/${userToken}/${sessionToken}`
    )
}

function getRemainingSolutions(userToken: string, sessionToken: string) {
    return axiosInstance.get<Solution[]>(
        `/remaining_solutions/${userToken}/${sessionToken}`
    )
}

function postSaveUserResponse(
    userToken: string,
    sessionToken: string,
    req: Response
) {
    return axiosInstance.post(
        `/save_user_response/${userToken}/${sessionToken}`,
        req
    )
}

function postSaveUsefulnessSolution(
    userToken: string,
    sessionToken: string,
    req: UserfulnessSolution
) {
    return axiosInstance.post(
        `/save_usefulness_solution/${userToken}/${sessionToken}`,
        req
    )
}

function postOsTicket(req: OsTicketParameters) {
    return axiosInstance
        .post(`/osticket`, req)
        .then(() => {
            return 'success'
        })
        .catch(() => {
            return 'error'
        })
}

export {
    login,
    getNextQuestion,
    getRemainingSolutions,
    postSaveUserResponse,
    postSaveUsefulnessSolution,
    postOsTicket,
}
