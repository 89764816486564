import React, { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import { Buttons, Button, Block } from './styles'
import stbernardIdleImage from '../../assets/images/stbernard/idle.png'
import coachIdleImage from '../../assets/images/coach/idle.png'
import useApplicationParams from '../../hooks/useApplicationParams'
import { STBERNARD_APPLICATION_NAME } from '../../helpers/constants'

const Div = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;
    padding: 1rem;
`

const Title = styled(Block)`
    margin: 1rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    font-weight: bold;
`

const Text = styled(Block)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin: 1rem 0;
`

interface ImageProps
    extends React.DetailedHTMLProps<
        React.ImgHTMLAttributes<HTMLImageElement>,
        HTMLImageElement
    > {
    className?: string
}

const ImageStyled = styled.img`
    ${(props) =>
        props.src === coachIdleImage
            ? css`
                  max-height: 150px;
              `
            : css`
                  max-height: 120px;
              `}
`

const ImageWrapper: FunctionComponent<ImageProps> = ({ className, src }) => (
    <div className={className}>
        <ImageStyled src={src} />
    </div>
)

const Image = styled(ImageWrapper)`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
`

interface Props {
    onStart?: () => void
    loading?: boolean
}

const Welcome: FunctionComponent<Props> = (props) => {
    const [applicationName,] = useApplicationParams()
    return (
        <Div>
            <Title>
                Bienvenue sur le <br />{' '}
                {applicationName === STBERNARD_APPLICATION_NAME
                    ? 'Saint Bernard COMWATT'
                    : 'Coach Énergétique'}
            </Title>
            <Image
                src={
                    applicationName === STBERNARD_APPLICATION_NAME
                        ? stbernardIdleImage
                        : coachIdleImage
                }
            />
            <Text>
                {applicationName === STBERNARD_APPLICATION_NAME
                    ? 'Pour tout problème avec votre solution Comwatt, je vais vous poser quelques questions et nous allons régler tout cela ensemble et rapidement ok ?'
                    : "Vous souhaitez faire encore plus d'économies d'énergie ? Pas de problème, suivez le coach et votre facture va maigrir à vue d'oeil. Ok"}
            </Text>
            <Buttons>
                <Button loading={props.loading} onClick={props.onStart}>
                    Commencer
                </Button>
            </Buttons>
        </Div>
    )
}

export default Welcome
