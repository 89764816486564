import { Action, action, Thunk, thunk } from 'easy-peasy'
import uuidv4 from 'uuid/v4'
import { Question } from '../types/api/question'
import {
    getNextQuestion,
    postSaveUserResponse,
    getRemainingSolutions,
    postSaveUsefulnessSolution,
} from '../api/api'
import findIndex from 'lodash/findIndex'
import take from 'lodash/take'
import { Solution } from '../types/api/solution'
import QuestionWithStates from '../types/QuestionWithStates'
import SolutionWithStates from '../types/SolutionWithStates'
import cloneDeep from 'lodash/cloneDeep'
import find from 'lodash/find'
import { sendRedirect, sendState } from '../utils/iframe'
import { MAX_SOLUTIONS } from '../helpers/constants'
import compact from 'lodash/compact'

interface AssistantStates {
    initialized: boolean
    loading: boolean
    userToken: string
    sessionToken: string
    questions: QuestionWithStates[]
    solutions: SolutionWithStates[]
    noMoreQuestions: boolean
}

interface ResponsePayload {
    questionID: number
    yesOrNo: boolean
}

interface FeedbackPayload {
    solutionID: number
    goodOrNot: boolean
}

interface StateObject {
    state: AssistantStates
    updatedAt: string // Date
}

interface AssistantActions {
    initTokens: Action<AssistantStates>
    init: Thunk<AssistantActions, void>
    markInitialized: Action<AssistantStates>
    newSession: Thunk<AssistantActions, void>
    restoreFromLocalStorage: Action<AssistantStates>
    saveToLocalStorage: Action<AssistantStates>
    loadFirstQuestion: Thunk<AssistantActions, void>
    loadNextQuestion: Thunk<AssistantActions, void>
    setQuestions: Action<AssistantStates, Question[]>
    setResponse: Thunk<AssistantActions, ResponsePayload>
    setSolutions: Action<AssistantStates, Solution[]>
    setLoading: Action<AssistantStates, boolean>
    clearStates: Action<AssistantStates>
    setFeedback: Thunk<AssistantActions, FeedbackPayload>
    setNoMoreQuestions: Action<AssistantStates, boolean>
}

export interface AssistantModel extends AssistantStates, AssistantActions {}

const assistant: AssistantModel = {
    initialized: false,
    loading: false,
    userToken: '',
    sessionToken: '',
    questions: [],
    solutions: [],
    noMoreQuestions: false,
    init: thunk(async (actions, _, helpers) => {
        console.log('Assistant init')

        await actions.restoreFromLocalStorage()
        await actions.markInitialized()

        const state = helpers.getState() as AssistantStates
        const stateObj = {
            loading: state.loading,
            found:
                state.questions.length > 0 &&
                state.solutions.length <= MAX_SOLUTIONS,
        }
        sendState(stateObj)
    }),
    markInitialized: action(state => {
        state.initialized = true
    }),
    initTokens: action(state => {
        if (!state.userToken) {
            state.userToken = uuidv4()
        }
        if (!state.sessionToken) {
            const timestamp = new Date().getTime()
            state.sessionToken = timestamp.toString()
        }
    }),
    loadNextQuestion: thunk(async (_, __, helpers) => {
        const state = helpers.getState() as AssistantStates

        const questionResp = await getNextQuestion(
            state.userToken,
            state.sessionToken
        )
        const question = questionResp.data || null

        const solutionsResp = await getRemainingSolutions(
            state.userToken,
            state.sessionToken
        )
        const solutions = solutionsResp.data

        return {
            question,
            solutions,
        }
    }),
    loadFirstQuestion: thunk(async (actions, _, __) => {
        await actions.setLoading(true)
        const { question, solutions } = await actions.loadNextQuestion()
        if (question) {
            await actions.setQuestions([question])
            actions.setNoMoreQuestions(false)
        } else {
            actions.setNoMoreQuestions(true)
        }
        await actions.setSolutions(solutions)
        await actions.setLoading(false)
    }),
    setQuestions: action((state, questions) => {
        state.questions = questions
    }),
    setSolutions: action((state, solutions) => {
        state.solutions = solutions
    }),
    setLoading: action((state, loading) => {
        state.loading = loading
        sendState({
            loading: state.loading,
            found: state.solutions.length <= MAX_SOLUTIONS,
        })
    }),
    setResponse: thunk(async (actions, payload, helpers) => {
        await actions.setLoading(true)
        const state = helpers.getState() as AssistantStates

        const index = findIndex(state.questions, {
            id: payload.questionID,
        })
        const previousQuestion = index > 0 ? state.questions[index - 1] : null

        // On ajoute l'état loading et la réponse à la question en cours
        const questions2 = cloneDeep(state.questions)
        const currentQuestion2 = questions2[index]
        currentQuestion2.yesOrNo = payload.yesOrNo
        currentQuestion2.loading = true
        await actions.setQuestions(questions2)

        // On poste la réponse et on récupère la dernière question et solutions
        await postSaveUserResponse(state.userToken, state.sessionToken, {
            previousQuestion: previousQuestion ? previousQuestion.id : null,
            question: payload.questionID,
            response: payload.yesOrNo,
        })
        const { question, solutions } = await actions.loadNextQuestion()

        /**
         * On supprime l'état loading sur toutes les questions et on supprime
         * les anciennes questions suivantes
         */
        const state2 = helpers.getState() as AssistantStates
        const __questions = cloneDeep(state2.questions)
        const previousQuestions = take(__questions, index + 1)
        previousQuestions.forEach(question => (question.loading = false))
        const _questions = [...previousQuestions, question]
        const questions = compact(_questions)

        if (question) {
            actions.setNoMoreQuestions(false)
        } else {
            actions.setNoMoreQuestions(true)
        }

        // On redirige sur la première solution si il y en a une
        let redirectFirstSolution: boolean = false
        if (solutions.length <= MAX_SOLUTIONS && solutions.length > 0) {
            solutions[0].redirected = true
            redirectFirstSolution = true
        }

        await actions.setQuestions(questions)
        await actions.setSolutions(solutions)

        await actions.setLoading(false)
        await actions.saveToLocalStorage()

        if (redirectFirstSolution) {
            const solution = solutions[0]
            sendRedirect(solution.uri)
        }
    }),
    setFeedback: thunk(async (actions, payload, helpers) => {
        await actions.setLoading(true)
        const state = helpers.getState() as AssistantStates

        const solutions = cloneDeep(state.solutions)
        const solution = find(solutions, {
            id: payload.solutionID,
        })

        let nextSolution = null
        if (solution) {
            solution.loading = true
            solution.goodOrNot = payload.goodOrNot

            const index = findIndex(solutions, {
                id: solution.id,
            })
            const nexIndex = index + 1
            nextSolution =
                nexIndex < solutions.length ? solutions[nexIndex] : null

            if (nextSolution && !payload.goodOrNot) {
                nextSolution.redirected = true
            }
        }

        await actions.setSolutions(solutions)

        await postSaveUsefulnessSolution(state.userToken, state.sessionToken, {
            solution: payload.solutionID,
            useful: payload.goodOrNot,
        })

        const state2 = helpers.getState() as AssistantStates
        const solutions2 = cloneDeep(state2.solutions)
        solutions2.forEach(solution => (solution.loading = false))
        await actions.setSolutions(solutions2)

        await actions.setLoading(false)
        await actions.saveToLocalStorage()

        if (nextSolution && nextSolution.redirected) {
            sendRedirect(nextSolution.uri)
        }
    }),
    setNoMoreQuestions: action((state, payload) => {
        state.noMoreQuestions = payload
    }),
    newSession: thunk(async actions => {
        await actions.clearStates()
        await actions.initTokens()
        await actions.loadFirstQuestion()
        await actions.saveToLocalStorage()
    }),
    clearStates: action(state => {
        state.loading = false
        state.sessionToken = ''
        state.questions = []
        state.solutions = []
    }),
    restoreFromLocalStorage: action(state => {
        const objStr = localStorage.getItem('assistantStates')
        if (objStr) {
            const obj: StateObject = JSON.parse(objStr)
            if (!obj.state || !obj.updatedAt) {
                return
            }

            const updatedAt = new Date(obj.updatedAt).getTime()
            const now = new Date().getTime()
            const oneDay = 24 * 60 * 60 * 1000 // 24h

            /**
             * Si la session a été utilisé dans les dernières 24h on réstore
             * sinon on clean
             */
            if (now - updatedAt < oneDay) {
                Object.keys(obj.state).forEach(key => {
                    ;(state as any)[key] = (obj.state as any)[key]
                })
            } else {
                state.userToken = obj.state.userToken
                const obj2: StateObject = {
                    state,
                    updatedAt: new Date().toString(),
                }
                const obj2Str = JSON.stringify(obj2)
                localStorage.setItem('assistantStates', obj2Str)
            }
        }
    }),
    saveToLocalStorage: action(state => {
        const obj: StateObject = {
            state,
            updatedAt: new Date().toString(),
        }
        const objStr = JSON.stringify(obj)
        localStorage.setItem('assistantStates', objStr)
    }),
}

export default assistant
