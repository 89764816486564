import React, { FunctionComponent } from 'react'
import { CONTACT_INFOS } from '../helpers/constants'
import formatPhoneNumber from '../utils/formatPhoneNumber'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons'

const NoSolutionDesktop: FunctionComponent = () => (
    <div className="mb-5">
        <h1 className="text-center">
            Aucune solution ne correspond à vos attentes
        </h1>
        <div className="my-3">
            <div className="text-center">
                Nous vous invitons à prendre contact avec notre support
                technique.
            </div>
            <div className="mt-2 d-flex flex-column justify-content-center align-items-center">
                <div className="text-left">
                    {CONTACT_INFOS.phone && (
                        <div>
                            <FontAwesomeIcon
                                icon={faPhone}
                                color="#777"
                                flip="horizontal"
                                className="mr-2"
                            />
                            <a
                                className="phone-link"
                                href={`tel:${CONTACT_INFOS.phone}`}
                            >
                                {formatPhoneNumber(CONTACT_INFOS.phone)}
                            </a>
                        </div>
                    )}
                    <div>
                        <FontAwesomeIcon
                            icon={faEnvelope}
                            color="#777"
                            className="mr-2"
                        />
                        <a
                            href={`mailto:${CONTACT_INFOS.mail}`}
                            target="_blank"
                            rel="noreferrer"
                        >
                            Besoin d’aide ou de conseil ?
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
)

export default NoSolutionDesktop
