import React, { FunctionComponent, useRef, useEffect, useState } from 'react'
import { sendRedirect } from '../../utils/iframe'
import styled from 'styled-components'
import { useStore, useActions } from '../../store'
import take from 'lodash/take'
import SolutionEl from './SolutionEl'
import QuestionEl from './QuestionEl'
import HeaderEl from './HeaderEl'
import NoSolution from './NoSolution'
import Loading from './Loading'
import { Button } from './styles'
import { MAX_SOLUTIONS, STBERNARD_APPLICATION_NAME } from '../../helpers/constants'
import { SwitchTransition } from 'react-transition-group'
import { FadeTransition } from './styles'
import { ToastProvider } from 'react-toast-notifications'
import useApplicationParams from '../../hooks/useApplicationParams'


const ScrollView = styled.div`
    flex: 1;
    overflow: auto;
    max-height: calc(100% - 41px);
    scrollbar-width: thin;
    background-color: #f0f0f0;
`

const Div = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
`

const Center = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
`

const Dimmed = styled.div`
    font-size: 0.8rem;
`

const HeaderButton = styled(Button)`
    font-size: 0.8rem;
    margin: 0;
    padding: 0.495rem 1rem;
`

const View = styled.div`
    width: 100%;
    height: 100%;
`

const Flow: FunctionComponent = () => {
    const [applicationName,] = useApplicationParams()
    const scrollRef = useRef<HTMLDivElement>(null)
    const { questions, solutions, loading, noMoreQuestions } = useStore(
        state => ({
            questions: state.assistant.questions,
            solutions: state.assistant.solutions,
            loading: state.assistant.loading,
            noMoreQuestions: state.assistant.noMoreQuestions,
        })
    )
    const { setResponse, setFeedback, newSession } = useActions(actions => ({
        setResponse: actions.assistant.setResponse,
        setFeedback: actions.assistant.setFeedback,
        newSession: actions.assistant.newSession,
    }))
    const [showLoading, setShowLoading] = useState(false)

    const onRedirect = (url: string) => {
        sendRedirect(url)
    }

    const onResponse = async (questionID: number, yesOrNo: boolean) => {
        await setResponse({
            questionID,
            yesOrNo,
        })
    }

    const onFeedback = async (solutionID: number, goodOrNot: boolean) => {
        await setFeedback({
            solutionID,
            goodOrNot,
        })
    }

    const onReset = async () => {
        setShowLoading(true)
        // Au moins 600ms entre deux flow, 2 * animation duration
        await Promise.all([
            await newSession(),
            await new Promise(res => setTimeout(res, 600)),
        ])
        setShowLoading(false)
    }

    const _questions =
        solutions.length > 0 && solutions.length <= MAX_SOLUTIONS
            ? take(questions, questions.length - 1)
            : questions

    const _solutions =
        solutions.length <= MAX_SOLUTIONS
            ? solutions.filter(solution => solution.redirected)
            : []

    const notUsefulSolutions = solutions.filter(
        solution => solution.goodOrNot === false
    )
    const noSolution =
        questions.length > 0 &&
        (solutions.length === 0 ||
            notUsefulSolutions.length === solutions.length)
    const noQuestion = questions.length === 0

    const showNoSolution =
        !loading &&
        (noSolution ||
            noQuestion ||
            (!_questions.length && !_solutions.length) ||
            noMoreQuestions)
    const showRemaining = _questions.length > 0

    useEffect(() => {
        if (loading === false) {
            if (scrollRef.current) {
                const div = scrollRef.current
                setTimeout(() => {
                    div.scrollIntoView({ block: 'end', behavior: 'smooth' })
                }, 1)
            }
        }
    }, [loading, _questions, solutions])

    return (
        <Div>
            <HeaderEl
                left={
                    applicationName === STBERNARD_APPLICATION_NAME
                        ? 'St. Bernard'
                        : 'Coach Énergétique'
                }
                right={
                    <HeaderButton disabled={showLoading} onClick={onReset}>
                        Recommencer
                    </HeaderButton>
                }
            />
            <ScrollView>
                <SwitchTransition mode="out-in">
                    <FadeTransition key={showLoading ? 'loading' : 'flow'}>
                        {showLoading ? (
                            <Loading />
                        ) : (
                            <View>
                                {_questions.map(question => (
                                    <QuestionEl
                                        key={question.id}
                                        question={question}
                                        onResponse={yesOrNo =>
                                            onResponse(question.id, yesOrNo)
                                        }
                                    />
                                ))}

                                {_solutions.map(solution => (
                                    <SolutionEl
                                        key={solution.id}
                                        solution={solution}
                                        onFeedback={goodOrNot =>
                                            onFeedback(solution.id, goodOrNot)
                                        }
                                        onRedirect={() =>
                                            onRedirect(solution.uri)
                                        }
                                    />
                                ))}

                                {showNoSolution && 
                                <ToastProvider autoDismiss={true} autoDismissTimeout={4000} placement={"bottom-right"}>
                                    <NoSolution/>
                                </ToastProvider>
                                }

                                <div ref={scrollRef} />

                                {showRemaining && (
                                    <Center>
                                        <Dimmed>
                                            ({solutions.length} solutions
                                            restantes)
                                        </Dimmed>
                                    </Center>
                                )}
                            </View>
                        )}
                    </FadeTransition>
                </SwitchTransition>
            </ScrollView>
        </Div>
    )
}

export default Flow
