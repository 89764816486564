import React, { FunctionComponent, FormEvent, useState, Fragment } from 'react'
import { useStore, useActions } from '../store'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'

const SignIn: FunctionComponent = props => {
    const [emailState, setEmailState] = useState('')
    const [passwordState, setPasswordState] = useState('')
    const sending = useStore(state => state.auth.sending)
    const login = useActions(actions => actions.auth.login)

    const onSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault()

        login({
            email: emailState,
            password: passwordState,
        })
    }

    return (
        <div className="flex-fill d-flex justify-content-center align-items-center">
            <div className="card shadow">
                <form
                    className="card-body d-flex flex-column"
                    onSubmit={onSubmit}
                >
                    <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input
                            id="email"
                            className="form-control"
                            type="email"
                            value={emailState}
                            onChange={event =>
                                setEmailState(event.target.value)
                            }
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="password">Mot de passe</label>
                        <input
                            id="password"
                            className="form-control"
                            type="password"
                            value={passwordState}
                            onChange={event =>
                                setPasswordState(event.target.value)
                            }
                        />
                    </div>
                    <button className="btn btn-primary" disabled={sending}>
                        {sending ? (
                            <Fragment>
                                <FontAwesomeIcon
                                    icon={faCircleNotch}
                                    color="#fff"
                                    spin
                                    className="mr-2"
                                />
                                Connexion en cours
                            </Fragment>
                        ) : (
                            <Fragment>Se connecter</Fragment>
                        )}
                    </button>
                </form>
            </div>
        </div>
    )
}

export default SignIn
